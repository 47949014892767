.App {
  overflow-x: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}

.ReactModalPortal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: white;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.smallText {
  font-size: 11px;
}

.privacyText {
  text-transform: none;
}

.spiel {
  background-color: #30424d;
  color: #fff;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  font-family: 'Playfair Display', sans-serif;
  font-family: 'Cormorant Garamond', serif;
  font-family: 'BebasNeueRegular', 'Arial Narrow', Arial, sans-serif;
  font-family: 'Work Sans', sans-serif;
}

.spiel h3 {
  font-family: 'Playfair Display', sans-serif;
  font-family: 'Cormorant Garamond', serif;
}

.videoWrapper * {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  background: #30424d;
  overflow: hidden;
  object-fit: cover;
}

.blurbContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  color: #FFF;
  text-align: center;
  overflow: hidden;
}

.blurbContainer h1 {
  font-size: 80px;
  line-height: 60px;
  margin-bottom: 20px;
}

.blurbContainer h2 {
  font-size: 20px;
  font-family: 'Cormorant Garamond', serif;
  font-family: 'Work Sans', sans-serif;
  line-height: 26px;
  width: 800px;
}

@media all and (max-width: 640px) {
  .blurbContainer h1 {
    font-size: 60px;
  }

  .blurbContainer h2 {
    font-size: 18x;
    padding: 0 10px;
    width: 100%;
  }
}

.videoWrapper iframe {
  width: 100vw;
  height: 56.25vw;
  min-height: 100vh;
  min-width: 177.77vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 1;
  opacity: 0.4;
}

h1 {
  opacity: 100%;
  font-family: 'Playfair Display', sans-serif;
  font-family: 'Cormorant Garamond', serif;
  font-family: 'BebasNeueRegular', 'Arial Narrow', Arial, sans-serif;
  font-family: 'Work Sans', sans-serif;
  font-size: 108px;
  padding: 0;
  margin: 0;
  z-index: 2;
}

@media all and (max-width: 640px) {
  h1 {
    font-size: 72px;
  }
}

h1 {
  animation: 2s anim-lineUp ease-out;
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 0.6;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes anim-lineUp-full {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 0.6;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

header {
  width: 100%;
  padding: 23px 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}

.nav {
  width: 100%;
}

header {
  width: 100%;
  padding: 27px 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}

.nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 180px;
}

@media all and (min-width: 640px) {
  header {
    padding: 23px 0;
  }
  .logo {
    width: 260px;
  }
}

.line-after {
  height: 30px;
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center !important;
}

.line-after:after {
  content: '';
  display: block;
  width: 2px;
  height: 30px;
  background-color: #e6e7ec;
}

.drawer {

}

.menu {
  display: flex;
}

.menu .item {
  display: none;
}

.menu a {
  color: #bfbab2;
  text-decoration: none;
}

.menu li {
  text-decoration: none;
  margin: auto;  /* Magic! */
  position:relative;
}

.menu li a {
  color: #b5845c;
  font-family: 'Playfair Display', sans-serif;
  font-size: 1.1em;
  /* font-family: "Poppins", sans-serif; */
  font-family: 'Cormorant Garamond', serif;
  padding: 0px 10px;
  transition: all 0.3s linear;
}

.menu li button {
  font-size: 1em;
  font-family: 'Playfair Display', sans-serif;
  /* font-family: "Poppins", sans-serif; */
  font-family: 'Cormorant Garamond', serif;
}

.menu a:hover {
  color: rgba(255,255,255,0.3);
}

.menu,
.submenu {
  list-style-type: none;
}

.menu li {
  padding: 0px 7px;
}

.menu .toggle .hamburger {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5px 7px 0;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}

.menu .toggle input:checked ~ * .drawer {

}

.menu .toggle input:checked + .menu .toggle .hamburger {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menu .toggle .l1 {
  margin-bottom: 3px;
}

.quote-btn {
  background-color: #b5845c;
  background-color: #30424d;
  border: none;
  border-radius: 7px;
  color: #FFF;
  cursor: pointer;
  padding: 7px 13px;
}

label .menu,
label .hamburger {
  display: none;
}
/* Desktop menu */
@media all and (min-width: 960px) {
  .menu .item {
    display: none;
  }
}

.root {
  height: 100%;
}

.intro-slideshow {
  display: table;
	height: 100%;
	width: 100%;
	margin: auto;
  background: transparent url(../public/bg/8.jpg) no-repeat center center fixed;
}

.intro-slideshow h2 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.intro-slideshow .inner {
	width:100%;
	max-width:none;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.intro-slideshow:after {
  content: '';
  background: transparent url(../public/bg/1.jpg) no-repeat center center fixed;
}

.intro-slideshow li span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  color: transparent;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: none;
  z-index: 9999999;
  animation: imageAnimation 36s linear infinite 0s;
}

.intro-slideshow li div {
  background-color: #30424d;
  z-index: 1000;
  position: absolute;
  bottom: 30px;
  left: 0px;
  width: 100%;
  text-align: center;
  opacity: 70%;
  color: #fff;
  animation: titleAnimation 36s linear infinite 0s;
}

.intro-slideshow li div h3 {
  background-color: #FFF;
  color: #000;
  position: fixed;
  bottom: 0;
  left: 0;
  font-family: 'BebasNeueRegular', 'Arial Narrow', Arial, sans-serif;
  font-size: 240px;
  padding: 0;
  line-height: 200px;
}

.page {
  z-index: 11;
}

.page .header {
  background-color: #30424d;
  /* padding: 30px 40px 22px; */
}

.page .header h3 {
  background: transparent url(../public/thatcher-icon.png) no-repeat;
  background-size: 30px 31px;
  color: #b5845c;
  font-family: "Poppins", sans-serif;
  font-family: 'Playfair Display', sans-serif;
  font-size: 20px;
  height: 40px;
  padding-left: 40px;
  padding-top: 3px;
}

/* Desktop menu */
@media all and (min-width: 960px) {
  .page .blurb {
    background-color: #fff;
    padding: 80px 40px;
    text-align: center;
    margin: auto;
  }
}

.page .services {
  background-color: #f2f3f8;
  padding: 80px 40px;
  text-align: center;
}

.page .services h2 {
  color: #434446;
  font-size: 30px;
  font-family: 'Cormorant Garamond', serif;
  margin-bottom: 20px;
}

.page .services h3 {
  color: #434446;
  font-size: calc(1.375rem + 1.5vw);
  font-family: "Poppins", sans-serif;
  font-family: 'Work Sans', sans-serif;
  margin-bottom: 20px;
}

.page .services p {
  text-align: center;
  color: #434446;
  font-family: 'Playfair Display', sans-serif;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 20px;
}

.footer {
  color: #8e8f90;
  font-family: "Poppins", sans-serif;
  padding: 20px;
}

.footer h3 {
  background: transparent url(../public/thatcher-icon.png) no-repeat;
  background-size: 50px 51px;
  font-size: 21px;
  font-family: "Poppins", sans-serif;
  font-family: 'Playfair Display', sans-serif;
  font-family: 'Work Sans', sans-serif;
  font-family: 'Cormorant Garamond', serif;
  font-weight: 400;
  margin: 20px 0;
  padding: 3px 60px 10px;
}

.footer .enquiry-btn {
  background-color: #b5845c;
  background-color: #30424d;
  border: none;
  border-radius: 7px;
  color: #FFF;
  cursor: pointer;
  font-size: 1.1em;
  font-family: 'Playfair Display', sans-serif;
  /* font-family: "Poppins", sans-serif; */
  font-family: 'Cormorant Garamond', serif;
  padding: 14px 26px;
}

.footer .copyright {
  border-top: 1px solid #CCC;
  font-size: .8em;
  margin-top: 40px;
  padding: 10px 0;
}

.footer .copyright ul {
  display: inline-flex;
  flex-direction: row;
  margin-bottom: 40px;
}

.footer .copyright li {
  margin-right: 10px;
  padding-right: 10px;
  position: relative;
}

.footer .copyright li:not(:last-child):after {
  content: '|';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: .5;
}

[data-am-gallery] {
  width:100vw;
  height: 100vh;
  /* Navigation */
}
[data-am-gallery] input[type="radio"] {
  position: fixed;
  top: -9999px;
}
[data-am-gallery] input[type="radio"]:checked:nth-child(5) ~ .images .image:nth-child(5) { opacity: 1; }
[data-am-gallery] input[type="radio"]:checked:nth-child(5) ~ .navigation .dot:nth-child(5) { background-color: #b5845c; }
[data-am-gallery] input[type="radio"]:checked:nth-child(5) ~ .navigation .dot:nth-child(5):hover { opacity: 1; }
[data-am-gallery] input[type="radio"]:checked:nth-child(4) ~ .images .image:nth-child(4) { opacity: 1; }
[data-am-gallery] input[type="radio"]:checked:nth-child(4) ~ .navigation .dot:nth-child(4) { background-color: #b5845c; }
[data-am-gallery] input[type="radio"]:checked:nth-child(4) ~ .navigation .dot:nth-child(4):hover { opacity: 1; }
[data-am-gallery] input[type="radio"]:checked:nth-child(3) ~ .images .image:nth-child(3) { opacity: 1; }
[data-am-gallery] input[type="radio"]:checked:nth-child(3) ~ .navigation .dot:nth-child(3) { background-color: #b5845c; }
[data-am-gallery] input[type="radio"]:checked:nth-child(3) ~ .navigation .dot:nth-child(3):hover { opacity: 1; }
[data-am-gallery] input[type="radio"]:checked:nth-child(2) ~ .images .image:nth-child(2) { opacity: 1; }
[data-am-gallery] input[type="radio"]:checked:nth-child(2) ~ .navigation .dot:nth-child(2) { background-color: #b5845c; }
[data-am-gallery] input[type="radio"]:checked:nth-child(2) ~ .navigation .dot:nth-child(2):hover { opacity: 1; }
[data-am-gallery] input[type="radio"]:checked:nth-child(1) ~ .images .image:nth-child(1) { opacity: 1; }
[data-am-gallery] input[type="radio"]:checked:nth-child(1) ~ .navigation .dot:nth-child(1) { background-color: #b5845c; }
[data-am-gallery] input[type="radio"]:checked:nth-child(1) ~ .navigation .dot:nth-child(1):hover { opacity: 1; }
[data-am-gallery] .image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
}

[data-am-gallery] .navigation {
  position: absolute;
  bottom: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
[data-am-gallery] .dot {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 3px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}
[data-am-gallery] .dot:hover { opacity: 0.8; }

.appointmentBtn {
  color: #FFF;
  position: fixed;
  z-index: 100;
  background: #000;
  border: solid 2px #000;
  transition: background .3s ease-out,.6s cubic-bezier(0.42, 0, 0.07, 0.99),-webkit-transform 1s cubic-bezier(0.63, 0, 0.08, 1);
  transition: transform 1s cubic-bezier(0.63, 0, 0.08, 1),background .3s ease-out,.6s cubic-bezier(0.42, 0, 0.07, 0.99);
  transition: transform 1s cubic-bezier(0.63, 0, 0.08, 1),background .3s ease-out,.6s cubic-bezier(0.42, 0, 0.07, 0.99),-webkit-transform 1s cubic-bezier(0.63, 0, 0.08, 1);
  -webkit-transform: translateX(130%);
  transform: translateX(130%);
  padding: 9px 18px;
  width: auto;
  align-items: center;
  justify-items: center;
  overflow: hidden;
  cursor: pointer;
  bottom: 25px;
  right: 20px;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  font-family: 'Cormorant Garamond', serif;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

#appointmentBtn {
  color: #FFF;
  position: fixed;
  z-index: 100;
  background: #000;
  overflow: hidden;
  cursor: pointer;
  bottom: 25px;
  right: 20px;
  padding: 9px 18px;
  transform: translateX(0);
  font-family: 'Cormorant Garamond', serif;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

#appointmentBtn a {
  position: relative;
  color: #FFF;
  transition: all .45s ease-Out;
  text-decoration: none;
}

#circle {
  width: 0%;
  height: 0%;
  opacity: 0;
  line-height: 40px;
  border-radius: 50%;
  background: #CCC;
  position: absolute;
  transition: all .5s ease-Out;
  top: 20px;
  left: 70px;
}

#appointmentBtn:hover #circle {
  width: 200%;
  height: 500%;
  opacity: 1;
  top: -70px;
  left: -70px;
}

#appointmentBtn:hover a {
  color: #2D3142;
}

.main__container {
  width: 100%;
  padding: 3px;
  max-width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.drawer {

}
