@import url('https://fonts.googleapis.com/css2?family=Aboreto&family=Bungee+Inline&family=Cormorant:wght@300&family=Work+Sans:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap');
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html, body {
	margin:0;
	padding:0;
	height:100%
}

body {
  /*background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
  margin: 0;
  font-size: small;
  font-family: "Poppins", sans-serif;
  color: #FFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
  overflow-x: hidden;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul, li {
  list-style-type: none;
  list-style: none;
  text-decoration: none;
}
